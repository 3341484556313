import LoadingButtonProvider from '@/components/buttons/LoadingButtonProvider';
import { generateQuery } from '@/helpers/pathName';
import { EMAIL_REGEX_CHECK, PASSWORD_REGEX } from '@/helpers/validate';
import useLogin from '@/hooks/auth/useLogin';
import { ILoginForm, LoginInput } from '@/interfaces/auth.interface';
import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { PATH_NAME } from 'configs/pathName';
import Button from 'design-system/components/Button/Button';
import Icon from 'design-system/components/Icon/Icon';
import { Typography } from 'design-system/components/Typography';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const LoginFormSection = ({
  forgetPassword,
}: {
  forgetPassword: React.ReactNode;
}) => {
  const router = useRouter();
  const { nfcId, redirectUrl } = router.query;

  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<ILoginForm>({
    defaultValues: {
      credentials: '',
      password: '',
    },
    resetOptions: {
      keepDefaultValues: true,
    },
  });

  const { control, handleSubmit, getValues } = methods;

  const { mutate, isLoading } = useLogin(methods, {
    onSuccess(data) {
      if (data.isInfoFullySubmitted) {
        router.replace(
          nfcId
            ? `${PATH_NAME.LIBRARY}?nfcId=${nfcId}`
            : (redirectUrl as string) || PATH_NAME.LIBRARY
        );
        return;
      }

      router.push({
        pathname: PATH_NAME.COMPLETE_INFO_EMAIL,
        query: generateQuery(router),
      });
    },
  });

  const onSubmit = () => {
    const data: LoginInput = {
      password: getValues('password'),
    };

    const credentials = getValues('credentials');
    if (EMAIL_REGEX_CHECK.test(credentials)) {
      data.email = credentials;
    } else {
      data.phoneNumber = credentials;
    }

    mutate({
      ...data,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full space-y-8 xl:space-y-6"
    >
      <div className="space-y-4">
        <Controller
          control={control}
          name="credentials"
          rules={{
            required:
              'Đây là trường thông tin bắt buộc. Vui lòng không bỏ trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} fullWidth variant="filled">
              <InputLabel required htmlFor="credentials">
                Email hoặc số điện thoại
              </InputLabel>
              <FilledInput
                {...field}
                onChange={(e) => {
                  e.target.value = e.target.value.trim();
                  field.onChange(e);
                }}
                id="credentials"
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required:
              'Đây là trường thông tin bắt buộc. Vui lòng không bỏ trống',
            pattern: {
              message: 'Mật khẩu phải có từ 6-20 ký tự.',
              value: PASSWORD_REGEX,
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              fullWidth
              variant="filled"
              className="!mb-4"
            >
              <InputLabel required htmlFor="password">
                Mật khẩu
              </InputLabel>
              <FilledInput
                {...field}
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <Icon
                      className="cursor-pointer"
                      onClick={() => setShowPassword((_) => !_)}
                      iconName={showPassword ? 'eye-slash' : 'eye'}
                    />
                  </InputAdornment>
                }
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </div>
      {forgetPassword}
      <LoadingButtonProvider
        className="w-full rounded-full"
        isLoading={isLoading}
      >
        <Button type="submit" className="w-full" size="medium" emphasis="high">
          <Typography variant="button2">Đăng nhập</Typography>
        </Button>
      </LoadingButtonProvider>
    </form>
  );
};
export default LoginFormSection;
