import GreyLoadingOverlay from '../loading/GreyLoadingOverlay';

interface ILoadingButtonProvider {
  children: React.ReactNode;
  isLoading: boolean;
  progressSize?: number | string;
  className?: string;
}

const LoadingButtonProvider = ({
  children,
  isLoading,
  progressSize = 20,
  className = 'rounded-lg',
}: ILoadingButtonProvider) => {
  return (
    <div className={`relative ${className} overflow-hidden`}>
      <GreyLoadingOverlay show={isLoading} size={progressSize} />
      {children}
    </div>
  );
};

export default LoadingButtonProvider;
