export const EMAIL_REGEX_CHECK =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmailRegex = (email: string) => {
  //return null if not an email / email invalid form
  return String(email).toLowerCase().match(EMAIL_REGEX_CHECK);
};

export const PASSWORD_REGEX = /^.{6,20}$/;

export const validatePassword = (password: any) => {
  return String(password).match(PASSWORD_REGEX);
};

export const PHONE_REGEX = /^((\+[0-9]{2})|0)([0-9]{9})$/;

export const validatePhone = (phone: string) => {
  return String(phone).match(PHONE_REGEX);
};

export const validateTimeLargerThanCurrentDate = (time: string): boolean => {
  if (!time) return false;
  const timeParse = new Date(time).getTime();
  const currentTime = new Date().getTime();

  return timeParse > currentTime;
};

export const checkLinkConvention = (link: string): boolean => {
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  // above regex copy this urL: https://www.regextester.com/94502

  return urlRegex.test(link);
};

export const URL_REGEX_CHECK =
  /(?:(?:(?:[a-z]+:)?\/\/)|www\.)(?:\S+(?::\S*)?@)?(?:localhost|(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#][^\s"]*)?/gm;

export const execUrlFromString = (text: string) => {
  return URL_REGEX_CHECK.exec(text);
};
