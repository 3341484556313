import {
  EloginErrorMsg,
  IAuthResponse,
  ILoginForm,
  LoginInput,
} from '@/interfaces/auth.interface';
import { postLogin } from 'apis/tms/auth.api';
import { AxiosError } from 'axios';
import { campaignTracking } from 'common/campaignTracking';
import { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import AuthContext from 'state/auth/AuthContext';
import useToast from '../useToast';

export default function useLogin(
  methods: UseFormReturn<ILoginForm, any, undefined>,
  opt?: Omit<
    UseMutationOptions<IAuthResponse, unknown, LoginInput, void>,
    'mutationFn'
  >
) {
  const toast = useToast();
  const { setUserEmail } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    ...opt,
    mutationFn: postLogin,
    onSuccess(data, variable, _context) {
      campaignTracking({ origin: 'LOGIN' });

      toast(
        'Đăng nhập thành công, vui lòng chờ trong giây lát để được chuyển hướng.',
        'success'
      );

      if (data.email) {
        setUserEmail(data.email);
      }

      queryClient.refetchQueries({
        active: true,
      });

      opt?.onSuccess && opt.onSuccess(data, variable, _context);
    },
    onError(_error, _variable, _context) {
      opt?.onError && opt.onError(_error, _variable, _context);

      const error = _error as AxiosError<{ messages: string[] }>;

      if (error.response?.data.messages[0] === EloginErrorMsg.NOT_FOUND) {
        methods.setError('credentials', {
          message: 'Tài khoản không tồn tại.',
        });
        return;
      }
      if (error.response?.data.messages[0] === EloginErrorMsg.INVALID_PASS) {
        methods.setError('password', {
          message: 'Mật khẩu không chính xác.',
        });
        return;
      }
      if (
        error.response?.data.messages[0] === EloginErrorMsg.EMAIL_NOT_VERIFIED
      ) {
        methods.setError('credentials', {
          message:
            'Email chưa được xác thực. Vui lòng sử dụng số điện thoại để đăng nhập.',
        });
        return;
      }

      toast('Có lỗi xảy ra, vui lòng thử lại sau.', 'error');
    },
  });
}
