import dynamic from 'next/dynamic';

const CircularProgress = dynamic(
  () => import('@mui/material/CircularProgress')
);

const GreyLoadingOverlay = ({
  size = 50,
  show = false,
}: {
  size?: number | string;
  show: boolean | undefined;
}) => {
  return !show ? null : (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-bg_disabled/80">
      <CircularProgress size={size} />
    </div>
  );
};

export default GreyLoadingOverlay;
